import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SettingsService {
    http;
    constructor(http) {
        this.http = http;
    }
    searchTags(ctx) {
        return this.http.get(`${environment.base}/tags`, { params: API.makeParams(ctx) });
    }
    createTag(priority) {
        return this.http.post(`${environment.base}/tags`, priority);
    }
    deleteTag(tag) {
        return this.http.delete(`${environment.base}/tags/${tag}`);
    }
    searchPriorities(ctx) {
        return this.http.get(`${environment.base}/settings/priority`, { params: API.makeParams(ctx) });
    }
    searchMaterials(ctx) {
        return this.http.get(`${environment.base}/settings/materials`, { params: API.makeParams(ctx) });
    }
    createPriority(priority) {
        return this.http.post(`${environment.base}/settings/priority`, priority);
    }
    deletePriority(id) {
        return this.http.delete(`${environment.base}/settings/priority/${id}`);
    }
    searchStatuses(ctx) {
        return this.http.get(`${environment.base}/settings/status`, { params: API.makeParams(ctx) });
    }
    createStatus(status) {
        return this.http.post(`${environment.base}/settings/status`, status);
    }
    deleteStatus(id) {
        return this.http.delete(`${environment.base}/settings/status/${id}`);
    }
    findDimensionCategories(ctx) {
        return this.http.get(`${environment.base}/dimensions`, { params: API.makeParams(ctx) });
    }
    findFullDimensionCategories(ctx) {
        return this.http.get(`${environment.base}/dimensions/full`, { params: API.makeParams(ctx) });
    }
    getDimensionsByCategory(dimensionCategoryId) {
        return this.http.get(`${environment.base}/dimensions/${dimensionCategoryId}`);
    }
    searchColumns(ctx) {
        return this.http.get(`${environment.base}/settings/column`, { params: API.makeParams(ctx) });
    }
    createColumn(column) {
        return this.http.post(`${environment.base}/settings/column`, column);
    }
    deleteColumn(id) {
        return this.http.delete(`${environment.base}/settings/column/${id}`);
    }
    searchDimensionCategories(ctx) {
        return this.http.get(`${environment.base}/settings/dimensions`, { params: API.makeParams(ctx) });
    }
    searchTemplates(ctx) {
        return this.http.get(`${environment.base}/templates`, { params: API.makeParams(ctx) });
    }
    createTemplate(template) {
        return this.http.post(`${environment.base}/templates`, template);
    }
    patchTemplate(template) {
        return this.http.patch(`${environment.base}/templates`, template);
    }
    deleteTemplate(id) {
        return this.http.delete(`${environment.base}/templates/${id}`);
    }
    saveConfiguration(vars) {
        return this.http.put(`${environment.base}/configuration`, vars);
    }
    loadConfiguration() {
        return this.http.get(`${environment.base}/configuration`);
    }
    loadConfigSpecs() {
        return this.http.get(`${environment.base}/settings/system`);
    }
    createMaterial(material) {
        return this.http.post(`${environment.base}/settings/materials`, material);
    }
    deleteMaterial(id) {
        return this.http.delete(`${environment.base}/settings/materials/${id}`);
    }
    searchCustomFields(ctx) {
        return this.http.get(`${environment.base}/custom-fields/${ctx.scope}`, { params: API.makeParams(ctx) });
    }
    createCustomField(field) {
        return this.http.post(`${environment.base}/custom-fields/`, field);
    }
    deleteCustomField(id) {
        return this.http.delete(`${environment.base}/custom-fields/${id}`);
    }
    static ɵfac = function SettingsService_Factory(t) { return new (t || SettingsService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SettingsService, factory: SettingsService.ɵfac });
}
