export * from './lib/interfaces/ICategory';
export * from './lib/interfaces/IColumn';
export * from './lib/interfaces/IOrder';
export * from './lib/interfaces/IPartner';
export * from './lib/interfaces/IPriority';
export * from './lib/interfaces/IService';
export * from './lib/interfaces/IStatus';
export * from './lib/interfaces/IDocument';
export * from './lib/interfaces/ITemplate';
export * from './lib/interfaces/IApiResponse';
export * from './lib/interfaces/IDoc';
export * from './lib/interfaces/IFolder';
export * from './lib/interfaces/ISMS';
export * from './lib/interfaces/IEmail';
export * from './lib/interfaces/IClip';
export * from './lib/interfaces/IComplaint';
export * from './lib/format';
export * from './lib/enums';
export * from './lib/dto';
export * from './lib/customer/enums';
export * from './lib/dimensions';
export * from './lib/interfaces/IDocument';
export * from './lib/finance';
export * from './lib/tags';
export * from './lib/planning';
export * from './lib/custom-fields';
export * from './lib/interfaces/IKnowledge';
export * from './lib/cost-center';
export * from './lib/label-calc';
export var EVarScope;
(function (EVarScope) {
    EVarScope["PRIVATE"] = "private";
    EVarScope["PUBLIC"] = "public";
})(EVarScope || (EVarScope = {}));
export var EVarInput;
(function (EVarInput) {
    EVarInput["TEXT"] = "TEXT";
    EVarInput["NUMERIC"] = "NUMERIC";
    EVarInput["SECRET"] = "SECRET";
    EVarInput["FILE"] = "FILE";
    EVarInput["COLOR"] = "COLOR";
    EVarInput["JSON"] = "JSON";
})(EVarInput || (EVarInput = {}));
export var EPriceModel;
(function (EPriceModel) {
    EPriceModel["CALC"] = "calc";
    EPriceModel["SIMPLE"] = "simple";
    EPriceModel["CALC_SUM"] = "calc_sum";
    EPriceModel["COMPLEX"] = "complex";
    EPriceModel["STEP"] = "step";
})(EPriceModel || (EPriceModel = {}));
export const PRICE_MAP = [
    { model: EPriceModel.CALC, label: 'Kalkulacija' },
    { model: EPriceModel.SIMPLE, label: 'Enostavna' },
    { model: EPriceModel.COMPLEX, label: 'Formula' },
    { model: EPriceModel.STEP, label: 'Stopnje' },
];
export var ERegistrationType;
(function (ERegistrationType) {
    ERegistrationType["START"] = "start";
    ERegistrationType["PAUSE"] = "pause";
    ERegistrationType["LUNCH"] = "lunch";
})(ERegistrationType || (ERegistrationType = {}));
export var EPriceParameterType;
(function (EPriceParameterType) {
    EPriceParameterType["NUM"] = "number";
})(EPriceParameterType || (EPriceParameterType = {}));
export var EApprovalState;
(function (EApprovalState) {
    EApprovalState["REVISE"] = "revise";
    EApprovalState["APPROVE"] = "approve";
})(EApprovalState || (EApprovalState = {}));
