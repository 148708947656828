import { RouterModule } from '@angular/router';
import { UserGuardService } from './auth/user-guard.service';
import { WriteGuardService } from './auth/write-guard.service';
import { BaseComponent } from './base/base.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const routes = [
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    },
    {
        path: '',
        pathMatch: 'prefix',
        component: BaseComponent,
        canActivate: [UserGuardService],
        children: [
            {
                path: 'signature',
                pathMatch: 'prefix',
                loadChildren: () => import('./signature/signature.module').then((m) => m.SignatureModule),
            },
            {
                path: 'hours',
                pathMatch: 'prefix',
                loadChildren: () => import('./hours/hours.module').then((m) => m.HoursModule),
            },
            {
                path: 'forms',
                pathMatch: 'prefix',
                loadChildren: () => import('./forms/forms.module').then((m) => m.FormsModule),
            }, {
                path: 'cost-center',
                pathMatch: 'prefix',
                loadChildren: () => import('./cost-center/cost-center.module').then((m) => m.CostCenterModule),
            },
            {
                path: 'folders',
                pathMatch: 'prefix',
                loadChildren: () => import('./folders/folders.module').then((m) => m.FoldersModule),
            },
            {
                path: 'organizer',
                pathMatch: 'prefix',
                loadChildren: () => import('./organizer/organizer.module').then((m) => m.OrganizerModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'orders',
                pathMatch: 'prefix',
                loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersModule),
            },
            {
                path: 'calendar',
                pathMatch: 'prefix',
                loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'clip',
                pathMatch: 'prefix',
                loadChildren: () => import('./clip/clip.module').then((m) => m.ClipModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'dashboard',
                pathMatch: 'prefix',
                loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'users',
                pathMatch: 'prefix',
                loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'services',
                pathMatch: 'prefix',
                loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'partners',
                pathMatch: 'prefix',
                loadChildren: () => import('./partners/partners.module').then((m) => m.PartnersModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'settings',
                pathMatch: 'prefix',
                loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'planning',
                pathMatch: 'prefix',
                loadChildren: () => import('./planning/planning.module').then((m) => m.PlanningModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'pricing',
                pathMatch: 'prefix',
                loadChildren: () => import('./pricing/pricing.module').then((m) => m.PricingModule),
                canLoad: [WriteGuardService],
            },
            {
                path: 'info',
                pathMatch: 'prefix',
                loadChildren: () => import('./info/info.module').then((m) => m.InfoModule),
            },
            {
                path: 'formats',
                pathMatch: 'prefix',
                loadChildren: () => import('./formats/formats.module').then((m) => m.FormatsModule),
            },
            {
                path: 'projects',
                pathMatch: 'prefix',
                loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
            },
            {
                path: 'knowledge',
                pathMatch: 'prefix',
                loadChildren: () => import('./knowledge/knowledge.module').then((m) => m.KnowledgeModule),
            },
            {
                path: 'label/calc',
                pathMatch: 'prefix',
                loadChildren: () => import('./label-calc/label-calc.module').then((m) => m.LabelCalcModule),
            },
            {
                path: '**',
                redirectTo: '/organizer',
            },
        ],
    },
    {
        path: '**',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    },
];
export class AppRoutingModule {
    static ɵfac = function AppRoutingModule_Factory(t) { return new (t || AppRoutingModule)(); };
    static ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppRoutingModule });
    static ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }), RouterModule] });
}
