import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ISearchCtx, IApiResponse, IComplaint } from '@mvm/api-interfaces';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ComplaintsService {
    http;
    constructor(http) {
        this.http = http;
    }
    search(ctx) {
        return this.http.get(`${environment.base}/complaints`, { params: API.makeParams(ctx) });
    }
    create(payload) {
        return this.http.post(`${environment.base}/complaints/${payload.orderId}`, payload);
    }
    update(id, payload) {
        return this.http.put(`${environment.base}/complaints/${id}`, payload);
    }
    getComplaint(id) {
        return this.http.get(`${environment.base}/complaints/${id}`);
    }
    static ɵfac = function ComplaintsService_Factory(t) { return new (t || ComplaintsService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ComplaintsService, factory: ComplaintsService.ɵfac });
}
