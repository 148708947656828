export var ECostCenterParams;
(function (ECostCenterParams) {
    ECostCenterParams["PRINT_SPEED"] = "print_speed";
    ECostCenterParams["MAX_WIDTH"] = "print_width";
    ECostCenterParams["CLICK_LENGTH"] = "click_length";
})(ECostCenterParams || (ECostCenterParams = {}));
export var EAmortizationType;
(function (EAmortizationType) {
    EAmortizationType["NONE"] = "none";
    EAmortizationType["LENGTH"] = "length";
    EAmortizationType["TIME"] = "time";
    EAmortizationType["AREA"] = "area";
})(EAmortizationType || (EAmortizationType = {}));
