/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LabelCalcService {
    http;
    constructor(http) {
        this.http = http;
    }
    create(data) {
        return this.http.post(`${environment.base}/label-calc`, {
            spec: data,
            title: data.title,
            type: data.type,
        });
    }
    search(ctx) {
        return this.http.get(`${environment.base}/label-calc`, {
            params: API.makeParams(ctx),
        });
    }
    getLabelCalc(id) {
        return this.http.get(`${environment.base}/label-calc/${id}`);
    }
    updateLabelCalc(id, payload) {
        return this.http.put(`${environment.base}/label-calc/${id}`, {
            spec: payload,
            title: payload.title,
            type: payload.type,
        });
    }
    static ɵfac = function LabelCalcService_Factory(t) { return new (t || LabelCalcService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LabelCalcService, factory: LabelCalcService.ɵfac });
}
